// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-input[data-v-1db3e5aa] {
  width: 600px !important;
}
@media (max-width: 768px) {
.comment-input[data-v-1db3e5aa] {
    min-width: unset !important;
    width: inherit !important;
}
}
.new-comment-container[data-v-1db3e5aa] {
  padding-bottom: 16px;
  border-style: inset;
  margin-bottom: 8px;
  border-bottom: 1px;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/comments/NewCommentMessage.vue","webpack://./NewCommentMessage.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;ACDF;ACDE;AFCF;IAGI,2BAAA;IACA,yBAAA;ACCF;AACF;ADEA;EACE,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;ACCF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.comment-input {\n  width: 600px!important;\n  @include phone {\n    min-width: unset!important;\n    width: inherit!important;\n  }\n}\n\n.new-comment-container {\n  padding-bottom: 16px;\n  border-style: inset;\n  margin-bottom: 8px;\n  border-bottom: 1px;\n}\n",".comment-input {\n  width: 600px !important;\n}\n@media (max-width: 768px) {\n  .comment-input {\n    min-width: unset !important;\n    width: inherit !important;\n  }\n}\n\n.new-comment-container {\n  padding-bottom: 16px;\n  border-style: inset;\n  margin-bottom: 8px;\n  border-bottom: 1px;\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
